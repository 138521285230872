<template>
  <div>
    <div id="header_container_gray_box">
      <v-row no-gutters>
        <!-- Section Payoff -->
        <v-col cols="12" class="d-flex align-center element">
          <h2
            class="ma-0 pa-0"
            style="line-height: normal"
            v-bind:style="{ 'font-size': font_size }"
          >
            <!-- Payoff -->
            <span v-html="text"></span>
          </h2>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
    fontSize: {
      type: String,
    },
  },
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {},
  created() {},
  computed: {
    font_size() {
      if (this.$vuetify.breakpoint.xs) {
        return "0.9rem";
      } else if (this.$vuetify.breakpoint.sm) {
        return "1rem";
      } else if (this.$vuetify.breakpoint.md) {
        return "1rem";
      } else if (this.$vuetify.breakpoint.lg) {
        return "1.2rem";
      } else if (this.$vuetify.breakpoint.xl) {
        return "1.4rem";
      }
      return "0.9rem";
    },
  },
  watch: {},
};
</script>

<style scoped>
/* Small screens */

@media (max-width: 480px) {
  h2 {
    /* font-size: 1rem; */
  }
}

/* Not small Sceens */
@media (min-width: 480px) {
  h2 {
    /* font-size: 1.2rem; */
  }
}

h2 {
  padding: 0;
  margin: 0;
  /* font-size: 10vw; */
  background: radial-gradient(#ffffff, #dfdfdf, #ffffff);
  /* background: radial-gradient(red, green, blue); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 150% 150%;
  animation: gradient 10s ease infinite reverse;
  font-weight: bold;
}

@keyframes gradient {
  0% {
    background-position: left top;
  }
  15% {
    background-position: right top;
  }
  30% {
    background-position: left center;
  }
  45% {
    background-position: right center;
  }
  60% {
    background-position: left bottom;
  }
  75% {
    background-position: right bottom;
  }
}
</style>