
<template>
  <div
    id="app_preview_container"
    style="width: 280px; top: -200px; position: relative; left: 60px"
  >
    <div class="container-image-small">
      <img
        draggable="false"
        style="position:absolute;top0;left:0;z-index:20"
        :src="require('@/assets/graphics/landing/devices_frames/macbook.png')"
        class="laptop_frame d-flex justify-center"
        alt="laptop frame"
      />

      <!-- Black display background -->
      <div class="black-background" style="z-index: 10"></div>

      <img
        style="z-index: 20"
        class="inset-image-small"
        draggable="false"
        v-for="(image, index) in images_list"
        :key="image.url"
        :src="image.url"
        :alt="image.alt"
        :id="'inset_image_' + functionality + '_' + index"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images_list: {
      type: Array,
    },
    functionality: {
      type: String,
    },
    nexTimer: {
      type: Number,
      default: 8000,
    },
  },
  components: {},
  data: () => ({
    image_count: 0,
    show_image_index_prev: 0,
    show_image_index: 0,
    url: null,
    alt: null,
    title: null,
    desc: null,
    //
    timerAutoNext: null,
  }),
  methods: {
    next() {
      this.show_image_index_prev = this.show_image_index;

      if (this.show_image_index >= this.image_count - 1) {
        this.show_image_index = 0;
        this.url = this.images_list[0].url;
        this.alt = this.images_list[0].alt;
        this.title = this.images_list[0].title;
        this.desc = this.images_list[0].desc;
      } else {
        this.show_image_index += 1;
        this.url = this.images_list[this.show_image_index].url;
        this.alt = this.images_list[this.show_image_index].alt;
        this.title = this.images_list[this.show_image_index].title;
        this.desc = this.images_list[this.show_image_index].desc;
      }
    },
  },
  mounted() {
    this.image_count = this.images_list.length;
    this.url = this.images_list[0].url;
    this.alt = this.images_list[0].alt;
    this.title = this.images_list[0].title;
    this.desc = this.images_list[0].desc;

    if (this.timerAutoNext) clearInterval(this.timerAutoNext);

    setTimeout(() => {
      this.timerAutoNext = setInterval(() => {
        this.next();
      }, this.nexTimer);
    }, this.nexTimer / 2);

    document
      .getElementById(
        "inset_image_" + this.functionality + "_" + this.show_image_index
      )
      .classList.toggle("img_showed");
  },
  created() {},
  watch: {
    url() {
      // console.log(this.show_image_index_prev + "->" + this.show_image_index);

      // Hide prev, show current
      if (this.show_image_index_prev != this.show_image_index) {
        document
          .getElementById(
            "inset_image_" +
              this.functionality +
              "_" +
              this.show_image_index_prev
          )
          .classList.toggle("img_showed");
        document
          .getElementById(
            "inset_image_" + this.functionality + "_" + this.show_image_index
          )
          .classList.toggle("img_showed");
      }
    },
  },
};
</script>

<style scoped>
.container-image-small {
  position: relative;
  width: 144px;
  height: 200px;
}

.laptop_frame {
  height: 150px;
  z-index: 2000;
}

.black-background {
  position: absolute;
  top: 5px;
  left: 33px;
  width: 170px;
  height: 108px !important;
  -o-object-fit: cover !important;
  -o-object-position: top;
  object-position: top;
  object-fit: fill !important;
  object-position: top;
  background-color: black;
}

.inset-image-small {
  position: absolute;
  top: 5px;
  left: 33px;
  width: 170px;
  height: 108px !important;
  -o-object-fit: cover !important;
  -o-object-position: top;
  object-position: top;
  object-fit: fill !important;
  object-position: top;
  opacity: 0;
  transition: all 0.5s;
}

.inset-image-small.img_showed {
  opacity: 1;
  transition: all 0.5s;
  transition-delay: 0.5;
}
</style>