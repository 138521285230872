<template>
  <div class="white black--text">
    <v-row>
      <v-col
        cols="12"
        offset-md="2"
        md="8"
        offset-lg="3"
        lg="6"
        offset-xl="3"
        xl="6"
      >
        <div id="howToDescription">
          <heading1Section
            color="black"
            :dense="true"
            :text="$t('lp_h1_join_the_project')"
          />
          <heading2Section
            color="black"
            :dense="true"
            :text="$t('lp_h2_join_the_project')"
            style="margin-bottom: 2rem"
          />

          <quote class="quote" :text="$t('lp_quote_join_the_project')" />

          <paragraph class="paragraph" :text="$t('lp_p_detail_areas_1')" />

          <div class="text-center">
            <img
              data-aos="fade-right"
              draggable="false"
              :src="
                require('@/assets/graphics/landing/pictures/howto/areas/areas_01.jpeg')
              "
              style="max-height: 300px; height: 30vh"
              alt="reading qrcode"
            />
          </div>

          <paragraph class="paragraph" :text="$t('lp_p_detail_areas_2')" />
          <paragraph class="paragraph" :text="$t('lp_p_detail_areas_3')" />
          <paragraph class="paragraph" :text="$t('lp_p_detail_areas_4')" />
        </div>
      </v-col>
    </v-row>

    <div class="text-center" style="">
      <div class="d-flex justify-center app_preview_container"></div>
    </div>
    <seeAlso v-if="!wait_seeAlsoBtns" :back_section="back_section" />
    <btnBackHome :back_section="back_section" />
    <!-- <pageFooter height="120px" /> -->
  </div>
</template>

<script>
import heading1Section from "@/components/landings/heading1Section.vue";
import heading2Section from "@/components/landings/heading2Section.vue";
import sectionDescription from "@/components/landings/sectionDescription.vue";
import paragraph from "@/components/landings/paragraph.vue";
import seeAlso from "@/components/landings/blocks/seeAlso.vue";
import btnBackHome from "@/components/landings/btnBackHome.vue";
import cardAsButton from "@/components/landings/cardAsButton.vue";
import appOnMobilePhone from "@/components/landings/appOnMobilePhone.vue";
import "@/styles/landing.css";
import AOS from "aos";
import "aos/dist/aos.css";
import quote from "@/components/landings/quote.vue";
import pageFooter from "@/components/landings/blocks/pageFooter.vue";

export default {
  props: {},
  components: {
    pageFooter,
    heading1Section,
    heading2Section,
    sectionDescription,
    cardAsButton,
    appOnMobilePhone,
    paragraph,
    seeAlso,
    btnBackHome,
    quote,
  },
  data: () => ({
    back_section: null,
    wait_seeAlsoBtns: true,
  }),
  methods: {},
  mounted() {
    AOS.init();
    if (this.$route.params.from_section) {
      this.back_section = this.$route.params.from_section;
      console.log("back to:", this.$route.params.from_section);
    }
    setInterval(() => {
      this.wait_seeAlsoBtns = false;
    }, 500);
  },
  created() {},
  computed: {},
  watch: {},
};
</script>

<style>
</style>