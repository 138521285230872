<template>
  <div id="preview_container" class="text-right">


    <!-- On not XL screens -->
    <appOnMobilePhoneSmall
      functionality="app_preview_mobile"
      :images_list="images_list_mobile"
      v-if="!$vuetify.breakpoint.xl"
    />

    <appOnLaptopSmall
      functionality="app_preview_laptop"
      :images_list="images_list_laptop"
      v-if="!$vuetify.breakpoint.xl"
    />

    <!-- On XL screens -->
    <appOnMobilePhoneLarge
      functionality="app_preview_mobile"
      :images_list="images_list_mobile"
      v-if="$vuetify.breakpoint.xl"
    />
    <appOnLaptopLarge
      functionality="app_preview_laptop"
      :images_list="images_list_laptop"
      v-if="$vuetify.breakpoint.xl"
    />


  </div>
</template>

<script>
import appOnMobilePhoneSmall from "../appOnMobilePhoneSmall.vue";
import appOnLaptopSmall from "../appOnLaptopSmall.vue";

import appOnMobilePhoneLarge from "../appOnMobilePhoneLarge.vue";
import appOnLaptopLarge from "../appOnLaptopLarge.vue";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  props: {},
  components: {
    appOnMobilePhoneSmall,
    appOnLaptopSmall,
    //
    appOnMobilePhoneLarge,
    appOnLaptopLarge,
  },
  data: () => ({}),
  methods: {},
  mounted() {
    AOS.init();
  },
  created() {},
  computed: {
    images_list_mobile() {
      return [
        {
          url: require("@/assets/graphics/landing/pictures/howto/reporting/app_reporting_01.png"),
          alt: this.$t("lp_img_detail_report_managment_img_title_01"),
          title: this.$t("lp_img_detail_report_managment_img_title_01"),
          desc: this.$t("lp_img_detail_report_managment_img_desc_01"),
        },
        {
          url: require("@/assets/graphics/landing/pictures/howto/reporting/app_reporting_02.png"),
          alt: this.$t("lp_img_detail_report_managment_img_title_02"),
          title: this.$t("lp_img_detail_report_managment_img_title_02"),
          desc: this.$t("lp_img_detail_report_managment_img_desc_02"),
        },
        {
          url: require("@/assets/graphics/landing/pictures/howto/reporting/app_reporting_03.png"),
          alt: this.$t("lp_img_detail_report_managment_img_title_03"),
          title: this.$t("lp_img_detail_report_managment_img_title_03"),
          desc: this.$t("lp_img_detail_report_managment_img_desc_03"),
        },
        {
          url: require("@/assets/graphics/landing/pictures/howto/reporting/app_reporting_04.png"),
          alt: this.$t("lp_img_detail_report_managment_img_title_04"),
          title: this.$t("lp_img_detail_report_managment_img_title_04"),
          desc: this.$t("lp_img_detail_report_managment_img_desc_04"),
        },
      ];
    },
    images_list_laptop() {
      return [
        {
          url: require("@/assets/graphics/landing/pictures/app_preview/laptop_01.png"),
          alt: this.$t("lp_img_detail_report_managment_img_title_01"),
          title: this.$t("lp_img_detail_report_managment_img_title_01"),
          desc: this.$t("lp_img_detail_report_managment_img_desc_01"),
        },
        {
          url: require("@/assets/graphics/landing/pictures/app_preview/laptop_02.png"),
          alt: this.$t("lp_img_detail_report_managment_img_title_01"),
          title: this.$t("lp_img_detail_report_managment_img_title_01"),
          desc: this.$t("lp_img_detail_report_managment_img_desc_01"),
        },
      ];
    },
  },
  watch: {},
};
</script>

<style scoped>
/* Small screens */
@media (max-width: 480px) {
  #preview_container {
    height: 180px;
  }
}

/* **** */

/* Not Small screens */
@media (min-width: 480px) {
  #preview_container {
    height: 256px;
    /* width: 400px; */
  }
}

/* **** */

#preview_container {
  position: relative;
}

#preview_pc {
  position: absolute;
  top: 185px;
  left: 30px;
}

#preview_mobile {
  position: absolute;
  top: 60px;
  left: 210px;
  z-index: 1;
}

#preview_ipad {
  position: absolute;
  top: 20px;
  left: 80px;
}

#preview_macbook {
  position: absolute;
  top: 20px;
  left: 35px;
}

.device_frame {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>