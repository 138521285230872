<template>
  <div>
    <v-row no-gutters>
      <!-- Section Payoff -->
      <v-col cols="12" md="8" class="d-flex align-center element">
        <!-- Payoff -->
        <h3
          class="px-4 pb-4"
          style="line-height: normal"
          v-bind:style="{ color: color, 'font-size': font_size }"
          v-html="text"
        ></h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
    color: {
      type: String,
      default: "black",
    },
    dense: {
      type: Boolean,
      default: false
    },
  },
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {},
  created() {},
  computed: {
    font_size() {
      if (this.dense) {
        if (this.$vuetify.breakpoint.smAndDown) {
          return "0.9rem";
        } else {
          return "1rem";
        }
      } else {
        if (this.$vuetify.breakpoint.smAndDown) {
          return "1.2rem";
        } else {
          return "1.2rem";
        }
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
/* Small screens */
/* @media (max-width: 480px) {
  h2 {
    font-size: 1.4rem;
  }
} */

/* Not small Sceens */
/* @media (min-width: 480px) {
  h2 {
    font-size: 3rem;
  }
} */

h3 {
  font-weight: normal;
  
  /* mix-blend-mode: difference; */
  /* margin-left: 2rem; */
}

/* h1 {
  padding: 0;
  margin: 0;
  background: radial-gradient(#eede52, #ff0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 150%;
  animation: gradient 8s ease infinite reverse;
}

@keyframes gradient {
  0% {
    background-position: left top;
  }
  25% {
    background-position: right top;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right bottom;
  }
} */
</style>