<template>
  <div style="margin-top: -64px" class="black">
    <!-- <div class="red--text" style="position: relative; top: 60px; height: 200px">
      XS: {{ $vuetify.breakpoint.xs }} <br />
      SM: {{ $vuetify.breakpoint.sm }} <br />
      MD: {{ $vuetify.breakpoint.md }} <br />
      LG: {{ $vuetify.breakpoint.lg }} <br />
      XL: {{ $vuetify.breakpoint.xl }} <br />
    </div> -->

    <!-- Main Heading  -->
    <div
      style="min-height: 90vh"
      class="main_header_background"
      data-aos="fade-down"
      id="home_header"
    >
      <div
        style="padding-left: 1rem; padding-right: 1rem"
        class="section_header"
        v-bind:class="
          $vuetify.breakpoint.smAndDown
            ? ''
            : 'd-flex align-center justify-center'
        "
      >
        <v-row>
          <v-col v-bind="cols_layout_headings">
            <!-- <v-row
              no-gutters
              class="content_row_header"
              v-bind:style="$vuetify.breakpoint.smAndDown ? '' : 'top:-90px'"
            > -->
            <v-row>
              <!-- Headings -->
              <v-col
                cols="12"
                offset-sm="1"
                sm="10"
                offset-md="0"
                md="7"
                class="pa-4"
                style="align-self: center"
                v-bind:style="{
                  'margin-top': heading_margin,
                  'margin-bottom': heading_margin,
                }"
              >
                <v-row no-gutters style="margin-top: 4rem">
                  <v-col cols="12" md="8" lg="12">
                    <heading1TextEffect
                      :text="$t('lp_h1_home_page_header_1')"
                    />
                  </v-col>

                  <!-- <v-col cols="12">
                  <iframe
                    src="https://www.youtube.com/embed/y5L8FfqiK1s?&autoplay=1"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </v-col> -->
                </v-row>

                <v-row no-gutters class="mt-4">
                  <v-col cols="12">
                    <heading2TextEffect
                      class="d-block"
                      :text="$t('lp_h2_home_page_header_1')"
                    />
                  </v-col>
                </v-row>

                <!-- Six Steps Image -->
                <!-- <v-row>
                  <v-col
                    cols="12"
                    style="display: flex; justify-content: center"
                  >
                    <img
                      class="ma-3 mr-3 d-block mb-4"
                      :src="six_step_src"
                      :srcset="six_step_srcset"
                      contain
                      style="background-position: initial !important"
                      :style="[
                        $vuetify.breakpoint.smAndDown
                          ? { 'max-width': '85%' }
                          : { 'max-width': '100%' },
                      ]"
                      :to="{
                        name: 'LandingHome',
                      }"
                      alt="six steps for safety"
                      @click="logoClick()"
                    />
                  </v-col>
                </v-row> -->
              </v-col>

              <!-- App preview -->
              <v-col
                cols="12"
                offset-sm="1"
                sm="10"
                offset-md="0"
                md="5"
                class="px-4 pb-4 d-flex align-center justify-center"
              >
                <v-row no-gutters>
                  <v-col cols="12">
                    <div class="d-flex flex-column justify-center align-center">
                      <homeHeaderAppDevicePreview />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <!-- Phrase above CTA  -->
              <!-- <v-col
                cols="12"
                class="text-center pa-2"
                v-bind:class="contactSent ? 'mt-12' : ''"
              >
                <heading2TextEffect :text="$t('lp_h2_home_page_header_2')" />
              </v-col> -->

              <!-- <v-row
                v-bind:class="$vuetify.breakpoint.smAndDown ? 'mt-12' : 'mt-0'"
              >
                <v-col cols="12" style="display: flex; justify-content: center">

                </v-col>
              </v-row> -->

              <!-- CTA -->
              <v-col
                cols="12"
                offset-sm="1"
                sm="10"
                offset-md="0"
                md="12"
                class="mt-4 pa-4 text-center"
              >
                <!-- offset-sm="1" sm="10" -->
                <!-- v-if="contactSent != true" -->

                <!-- <btnCtaCollectContactHeading formName="homepage_heading" /> -->
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>

    <!-- Under headings body -->
    <div id="section_underheadings" style="">
      <v-row
        no-gutters
        class="content_row_underheadings"
        style="padding-top: 4.5rem"
      >
        <v-col
          style="min-height: 50vh"
          v-bind="cols_layout_underheadings"
          class="d-flex flex-column justify-center px-4"
        >
          <forWho />
        </v-col>

        <v-col cols="12" class="pa-4 text-center">
          <v-btn
            color="primary"
            class="px-4 no-uppercase"
            :height="50"
            text
            :x-large="$vuetify.breakpoint.smAndDown == false"
            :block="$vuetify.breakpoint.smAndDown"
            @click="scrollToId('section_areas', false)"
            >{{ $t("lp_btn_home_page_header") }}</v-btn
          >
        </v-col>

        <v-col cols="12" class="text-center">
          <v-btn
            :x-large="$vuetify.breakpoint.smAndDown == false"
            :large="$vuetify.breakpoint.smAndDown"
            icon
            @click="scrollToId('section_areas', false)"
            class="mb-8"
          >
            <v-icon
              :x-large="$vuetify.breakpoint.smAndDown == false"
              :large="$vuetify.breakpoint.smAndDown"
              >mdi-menu-down-outline</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- Features -->
    <div
      id="section_features"
      class="features_app"
      style="padding-top: 1rem; min-height: 380px; background: #ebebeb"
    >
      <v-row class="mx-0 pt-12" no-gutters>
        <v-col
          v-bind="cols_layout_app_features_preview"
          class="content_row_app_features_preview px-4"
          style="padding-bottom: 2rem"
        >
          <h2 v-bind:style="{ 'font-size': font_size_title }" class="who_title">
            {{ $t("af_home_some_features") }}
          </h2>

          <!-- <h4>
            {{ $t("") }}
          </h4> -->
        </v-col>

        <v-col v-bind="cols_layout_app_features_preview">
          <v-row np-gutters class="d-flex justify-center" data-aos="fade-right">
            <v-col
              v-for="feature in features_list()"
              :key="feature.img_path"
              cols="6"
              md="4"
              lg="3"
              xl="3"
            >
              <appFeature
                :img_path="feature.img_path"
                :img_alt="feature.img_alt"
                :front_text="feature.front_text"
                :back_title="feature.back_title"
                :back_text="feature.back_text"
                :analytics_event="feature.analytics_event"
                back_color="white"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          md="10"
          offset-md="1"
          class="text-center px-8"
          style="padding-top: 4rem; padding-bottom: 6rem"
        >
          <v-card
            class="btn_find_out_more_white_bg"
            :to="{
              name: 'LandingFeatures',
              params: { from_section: 'section_features' },
            }"
            color="primary"
          >
            <v-card-text
              class="secondary--text text-center btn_find_out_more_white_fg"
            >
              {{ $t("af_click_to_see_all_features") }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Striped separator -->
    <div class="stripe_separator" data-aos="fade-left"></div>
    <!-- <div class="stripe_separator"></div> -->

    <!-- Areas -->
    <div style="" id="section_areas" data-aos="fade-right">
      <v-row no-gutters class="content_row_areas" style="padding-top: 4.5rem">
        <v-col v-bind="cols_layout_section_headings">
          <heading2Section color="white" :text="$t('lp_h2_home_areas')" />
        </v-col>
        <v-col v-bind="cols_layout_section_headings">
          <heading3Section color="white" :text="$t('lp_h3_home_areas')" />
        </v-col>
        <v-col v-bind="cols_layout_sections">
          <sectionDescription color="white" :text="$t('lp_p_home_areas')" />
        </v-col>
        <v-col
          cols="12"
          md="10"
          offset-md="1"
          class="find_out_more px-8"
          v-bind:class="
            $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'
          "
        >
          <v-card
            class="btn_find_out_more_white_bg"
            :to="{
              name: 'HowToManageAreas',
              params: { from_section: 'section_areas' },
            }"
          >
            <v-card-text class="text-center btn_find_out_more_white_fg">
              {{ $t("lp_btn_find_out_more_areas") }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            :x-large="$vuetify.breakpoint.smAndDown == false"
            :large="$vuetify.breakpoint.smAndDown"
            icon
            @click="scrollToId('section_report', false)"
            class="mb-8"
          >
            <v-icon
              :x-large="$vuetify.breakpoint.smAndDown == false"
              :large="$vuetify.breakpoint.smAndDown"
              >mdi-menu-down-outline</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- Striped separator -->
    <div class="stripe_separator" data-aos="fade-left"></div>

    <!-- ---------------------- -->

    <!-- Reports dangers -->
    <div style="" id="section_report" data-aos="fade-right">
      <v-row no-gutters class="content_row_report" style="padding-top: 4.5rem">
        <v-col v-bind="cols_layout_section_headings">
          <heading2Section color="black" :text="$t('lp_h2_home_report')" />
        </v-col>
        <v-col v-bind="cols_layout_section_headings">
          <heading3Section color="black" :text="$t('lp_h3_home_report')" />
        </v-col>
        <v-col v-bind="cols_layout_sections">
          <sectionDescription color="black" :text="$t('lp_p_home_report_1')" />
        </v-col>
        <v-col
          cols="12"
          md="10"
          offset-md="1"
          class="find_out_more px-8"
          v-bind:class="
            $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'
          "
        >
          <v-card
            class="btn_find_out_more_black_bg"
            :to="{
              name: 'HowToManageReports',
              params: { from_section: 'section_report' },
            }"
          >
            <v-card-text class="text-center btn_find_out_more_black_fg">
              {{ $t("lp_btn_find_out_more_report") }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            :x-large="$vuetify.breakpoint.smAndDown == false"
            :large="$vuetify.breakpoint.smAndDown"
            icon
            @click="scrollToId('section_describe', false)"
            class="mb-8"
          >
            <v-icon
              :x-large="$vuetify.breakpoint.smAndDown == false"
              :large="$vuetify.breakpoint.smAndDown"
              >mdi-menu-down-outline</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- Striped separator -->
    <div class="stripe_separator" data-aos="fade-left"></div>

    <!-- Documenta -->
    <div id="section_describe" data-aos="fade-right">
      <v-row
        no-gutters
        class="content_row_describe"
        style="padding-top: 4.5rem"
      >
        <v-col v-bind="cols_layout_section_headings">
          <heading2Section color="white" :text="$t('lp_h2_home_describe')" />
        </v-col>
        <v-col v-bind="cols_layout_section_headings">
          <heading3Section color="white" :text="$t('lp_h3_home_describe')" />
        </v-col>
        <v-col v-bind="cols_layout_sections">
          <sectionDescription
            color="white"
            :text="$t('lp_p_home_describe_1')"
          />
          <sectionDescription
            color="white"
            :text="$t('lp_p_home_describe_2')"
          />
        </v-col>

        <!-- Bottom button div -->
        <v-col
          cols="12"
          md="10"
          offset-md="1"
          class="find_out_more px-8"
          v-bind:class="
            $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'
          "
        >
          <!-- <v-btn
            block
            text
            color="white"
            class="px-4 no-uppercase btn_find_out_more_white_bg"
            :to="{
              name: 'HowToManageEvents',
              params: { from_section: 'section_describe' },
            }"
            >{{ $t("lp_btn_find_out_more_describe") }}</v-btn
          > -->

          <v-card
            class="btn_find_out_more_white_bg"
            :to="{
              name: 'HowToManageEvents',
              params: { from_section: 'section_describe' },
            }"
          >
            <v-card-text class="text-center btn_find_out_more_white_fg">
              {{ $t("lp_btn_find_out_more_describe") }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            color="white"
            :x-large="$vuetify.breakpoint.smAndDown == false"
            :large="$vuetify.breakpoint.smAndDown"
            icon
            @click="scrollToId('section_communicate', false)"
            class="mb-8"
          >
            <v-icon
              :x-large="$vuetify.breakpoint.smAndDown == false"
              :large="$vuetify.breakpoint.smAndDown"
              >mdi-menu-down-outline</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- Striped separator -->
    <div class="stripe_separator" data-aos="fade-left"></div>

    <!-- Sharing -->
    <div id="section_communicate" data-aos="fade-right">
      <v-row
        no-gutters
        class="content_row_communicate"
        style="padding-top: 4.5rem"
      >
        <v-col v-bind="cols_layout_section_headings">
          <heading2Section color="black" :text="$t('lp_h2_home_communicate')" />
        </v-col>
        <v-col v-bind="cols_layout_section_headings">
          <heading3Section color="black" :text="$t('lp_h3_home_communicate')" />
        </v-col>
        <v-col v-bind="cols_layout_sections">
          <sectionDescription
            color="black"
            :text="$t('lp_p_home_communicate_1')"
          />
          <sectionDescription
            color="black"
            :text="$t('lp_p_home_communicate_2')"
          />
        </v-col>
        <!-- Bottom button div -->
        <v-col
          cols="12"
          md="10"
          offset-md="1"
          class="find_out_more px-8"
          v-bind:class="
            $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'
          "
        >
          <v-card
            class="btn_find_out_more_black_bg"
            :to="{
              name: 'HowToManageSharing',
              params: { from_section: 'section_communicate' },
            }"
          >
            <v-card-text class="text-center btn_find_out_more_black_fg">
              {{ $t("lp_btn_find_out_more_share") }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            :x-large="$vuetify.breakpoint.smAndDown == false"
            :large="$vuetify.breakpoint.smAndDown"
            icon
            @click="scrollToId('section_dashboard', false)"
            class="mb-8"
          >
            <v-icon
              :x-large="$vuetify.breakpoint.smAndDown == false"
              :large="$vuetify.breakpoint.smAndDown"
              >mdi-menu-down-outline</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- Striped separator -->
    <div class="stripe_separator" data-aos="fade-left"></div>

    <!-- Dashboard -->
    <div id="section_dashboard" data-aos="fade-right">
      <v-row
        no-gutters
        class="dashboard_descriptions_background"
        style="padding-top: 4.5rem"
      >
        <v-col v-bind="cols_layout_section_headings">
          <heading2Section color="white" :text="$t('lp_h2_home_dashboard')" />
        </v-col>
        <v-col v-bind="cols_layout_section_headings">
          <heading3Section color="white" :text="$t('lp_h3_home_dashboard')" />
        </v-col>
        <v-col v-bind="cols_layout_sections">
          <sectionDescription
            color="white"
            :text="$t('lp_p_home_dashboard_1')"
          />
          <sectionDescription
            color="white"
            :text="$t('lp_p_home_dashboard_2')"
          />
        </v-col>

        <!-- Bottom button div -->
        <v-col
          cols="12"
          md="10"
          offset-md="1"
          class="find_out_more px-8"
          v-bind:class="
            $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'
          "
        >
          <v-card
            class="btn_find_out_more_white_bg"
            :to="{
              name: 'HowToManageDashboard',
              params: { from_section: 'section_dashboard' },
            }"
          >
            <v-card-text class="text-center btn_find_out_more_white_fg">
              {{ $t("lp_btn_find_out_more_dashboard") }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- <div>
      <v-btn color="black" text :to="{ name: 'LandingNewAccount' }"
        >New account</v-btn
      >
    </div> -->

    <!-- Bottom space -->
    <div id="box_bottom_space"></div>
  </div>
</template>

<script>
import heading1TextEffect from "@/components/landings/heading1TextEffect.vue";
import heading2TextEffect from "@/components/landings/heading2TextEffect.vue";
import heading2Section from "@/components/landings/heading2Section.vue";
import heading3Section from "@/components/landings/heading3Section.vue";
import sectionDescription from "@/components/landings/sectionDescription.vue";
import appFeature from "@/components/landings/blocks/appFeature.vue";
import forWho from "@/components/landings/blocks/forWho.vue";

// import dialogCollectContact from "../../components/landings/blocks/dialogCollectContact.vue";
import btnCtaCollectContactHeading from "@/components/landings/blocks/btnCtaCollectContactHeading";
import homeHeaderAppDevicePreview from "@/components/landings/blocks/homeHeaderAppDevicePreview.vue";

import "@/styles/landing.css";

import AOS from "aos";
import "aos/dist/aos.css";

import { EventBus } from "@/main";
import { gtag } from "@/gtag";

import full_features from "@/mixins/app_features";
import { i18n } from "@/plugins/i18n";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default {
  metaInfo: {
    title: "Digitalizza la sicurezza sul lavoro nella tua azienda",
    meta: [
      {
        name: "description",
        content:
          // "Ricevi segnalazioni e consigli su come migliorare la sicurezza, comunica in modo efficace con i tuoi collaboratori. Riduci gli incidenti e gli infortuni.",
          "Migliora la sicurezza sul lavoro, coinvolgendo in modo innovativo i tuoi collaboratori. Riduci gli incidenti e gli infortuni.",
      },

      // // LINKEDIN SHARE
      // {
      //   property: "og:title",
      //   content: "SafetyFirst",
      // },
      // {
      //   property: "og:image",
      //   content: "//sa-first.com/img/social_share_centered2.png",
      // },

      // {
      //   property: "og:description",
      //   content:
      //     "Migliora la sicurezza sul lavoro utilizzando una comunicazione inclusiva",
      // },

      // {
      //   property: "og:url",
      //   content: "//sa-first.com/",
      // },
      // //

      // {
      //   property: "og:image:type",
      //   content: "image/png",
      // },
      // {
      //   property: "og:image:width",
      //   content: "382",
      // },
      // {
      //   property: "og:image:height",
      //   content: "200",
      // },
    ],
  },
  mixins: [full_features],
  props: ["scroll_to_section_id", "contact_open", "form_name"],
  components: {
    forWho,
    appFeature,
    //
    //
    //
    homeHeaderAppDevicePreview,
    //
    heading1TextEffect,
    heading2TextEffect,
    //
    heading2Section,
    heading3Section,
    sectionDescription,
    //
    //
    // dialogCollectContact,
    btnCtaCollectContactHeading,
    //
  },
  data: () => ({
    //
    lp_hide_underconstruction: null,
    //
    cols_layout_headings: {
      cols: "12",
      "offset-md": 1,
      md: 10,
      "offset-lg": 1,
      lg: 10,
      "offset-xl": 2,
      xl: 8,
    },
    cols_layout_section_headings: {
      cols: "10",
      "offset-md": 1,
      md: 10,
      "offset-lg": 1,
      lg: 10,
      "offset-xl": 3,
      xl: 6,
    },
    cols_layout_underheadings: {
      cols: "12",
      "offset-md": 1,
      md: 10,
      "offset-lg": 1,
      lg: 10,
      "offset-xl": 2,
      xl: 8,
    },
    cols_layout_app_features_preview: {
      cols: "12",
      "offset-md": 0,
      md: 12,
      "offset-lg": 1,
      lg: 10,
      "offset-xl": 1,
      xl: 10,
    },
    cols_layout_sections: {
      cols: "8",
      offset: 0,
      "offset-md": 1,
      md: 10,
      "offset-lg": 1,
      lg: 6,
      "offset-xl": 3,
      xl: 4,
    },
    cols_layout_apppreview: {},
    //
    // full_features: features,
    show_feature_indexes_small: [1, 4, 5, 15, 9, 10],
    show_feature_indexes_large: [1, 4, 5, 15],

    contactSent: null,
  }),
  methods: {
    consent_mode_grant_analytics() {
      console.log("consent_mode accept analytics");
      gtag("consent", "update", {
        analytics_storage: "granted",
      });
    },
    consent_mode_revoke_analytics() {
      console.log("consent_mode revoke analytics");
      gtag("consent", "update", {
        analytics_storage: "denied",
      });
    },
    features_list() {
      // console.log(this.show_feature_indexes);

      if (this.$vuetify.breakpoint.mdAndDown) {
        if (this.show_feature_indexes_small) {
          let ft_list = [];
          this.show_feature_indexes_small.forEach((index) => {
            ft_list.push(this.full_features[index]);
          });
          return ft_list;
        } else {
          return this.full_features;
        }
      } else {
        if (this.show_feature_indexes_large) {
          let ft_list = [];
          this.show_feature_indexes_large.forEach((index) => {
            ft_list.push(this.full_features[index]);
          });
          return ft_list;
        } else {
          return this.full_features;
        }
      }
    },

    testGrantConsent() {
      gtag("consent", "update", {
        analytics_storage: "granted", //---------
      });
    },

    testRevokeConsent() {
      gtag("consent", "update", {
        analytics_storage: "denied", //---------
      });
    },

    checkForContactAlreadySent() {
      if (localStorage.getItem("contactSent") == 1) {
        this.contactSent = true;
      } else {
        this.contactSent = false;
      }
    },

    requestDemo(calling_section) {
      console.log("requestDemo", calling_section);
      EventBus.$emit("cta_contact_collect", calling_section);
    },

    randomBackgroundHeader() {
      const max = 10;
      const image_id = getRandomInt(max - 1);
      const url = require("@/assets/graphics/landing/backgrounds/header/background_" +
        image_id +
        ".jpeg");
      document.documentElement.style.setProperty(
        "--main_header_background",
        "url(" + url + ")"
      );
    },
    navigateTo(to, from) {
      this.$router.push({ name: to, params: { from_section: from } });
    },
    scrollToId(id, withOffset = true) {
      console.log("scroll to id", id);
      if (withOffset) {
        const el = document.getElementById(id);
        if (el) {
          let distanceFromTop = el.getBoundingClientRect().top;
          let appBarHeight =
            document.getElementById("landing_top_bar").offsetHeight;
          let scroll_position = distanceFromTop - appBarHeight;
          window.scrollTo({ top: scroll_position, behavior: "smooth" });
        }
      } else {
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    },
  },
  mounted() {
    console.log("lp home");
    if (this.contact_open) {
      setTimeout(() => {
        this.requestDemo(this.form_name);
      }, 1000);
    }

    this.lp_hide_underconstruction = getCookie("lp_hide_underconstruction");

    this.checkForContactAlreadySent();

    const url = require("@/assets/graphics/landing/backgrounds/header/background_0.jpeg");
    document.documentElement.style.setProperty(
      "--main_header_background",
      "url(" + url + ")"
    );

    // this.randomBackgroundHeader();
    AOS.init();
    if (this.scroll_to_section_id) {
      setTimeout(() => {
        this.scrollToId(this.scroll_to_section_id);
      }, 100);
    } else {
      this.scrollToId("home_header");
    }

    // watching local storage for 'contactSent' value in order to remove CTA
    window.addEventListener("storage", () => {
      this.checkForContactAlreadySent();
    });

    EventBus.$on("contactSent", () => {
      this.contactSent = true;
    });

    // -----------------

    // gtag("consent", "default", {
    //   ad_storage: "denied",
    //   analytics_storage: "denied",
    //   wait_for_update: 2500,
    // });
    // gtag("set", "url_passthrough", true);
    // gtag("set", "ads_data_redaction", true);

    // setTimeout(() => {
    //   const lp_cookies_decision = getCookie("lp_cookies_decision");
    //   const analytics_storage = getCookie("analytics_storage");
    //   // console.log("lp_cookies_decision", lp_cookies_decision);
    //   // console.log("analytics_storage", analytics_storage);
    //   if (!lp_cookies_decision) {
    //     this.cookie_dialog = true;
    //     console.log("SHOW COOKIE DIALOG");
    //   } else {
    //     if (analytics_storage == "granted") {
    //       this.consent_mode_grant_analytics();
    //     } else {
    //       this.consent_mode_revoke_analytics();
    //     }
    //   }
    // }, 100);
  },
  created() {},
  computed: {
    six_step_src() {
      if (i18n.locale == "it") {
        return require("@/assets/graphics/landing/pictures/six_steps_it.png");
      } else {
        return require("@/assets/graphics/landing/pictures/six_steps_us.png");
      }
    },
    six_step_srcset() {
      if (i18n.locale == "it") {
        return (
          require("@/assets/graphics/landing/pictures/six_steps_it.png") + " 2x"
        );
      } else {
        return (
          require("@/assets/graphics/landing/pictures/six_steps_us.png") + " 2x"
        );
      }
    },
    font_size_title() {
      if (this.$vuetify.breakpoint.xs) {
        return "1.6rem";
      } else if (this.$vuetify.breakpoint.sm) {
        return "1.8rem";
      } else if (this.$vuetify.breakpoint.md) {
        return "2rem";
      } else if (this.$vuetify.breakpoint.lg) {
        return "2.5rem";
      } else if (this.$vuetify.breakpoint.xl) {
        return "3rem";
      }
      return "1rem";
    },
    heading_margin() {
      if (this.$vuetify.breakpoint.xs) {
        // return "2rem"
      } else if (this.$vuetify.breakpoint.sm) {
        // return "3rem"
      } else if (this.$vuetify.breakpoint.md) {
        // return "2rem"
      } else if (this.$vuetify.breakpoint.lg) {
        return "1rem";
      } else if (this.$vuetify.breakpoint.xl) {
        return "6rem";
      }
      return "1rem";
    },

    images_size() {
      if (this.$vuetify.breakpoint.smAndDown) return "small";
      if (this.$vuetify.breakpoint.lgAndUp) return "x-large";
      return "large";
    },
  },
  watch: {},
};
</script>

<style scoped>
.blink {
  animation: blinker 2s infinite;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

#first_card {
  position: relative;
  top: -30px;
}
</style>