<template>
  <div>
    <h2 v-bind:style="{ 'font-size': font_size_title }" class="who_title">
      {{ $t("lp_for_who_title") }}
    </h2>
    <br />
    <br />

    <!-- Owners -->
    <div data-aos="fade-right" class="who_owners">
      <v-row>
        <v-col cols="12" sm="8" >
          <v-card color="#ffffffb3">
            <v-card-text class="pa-6">
              <div class="card_heading">
                <div class="text-center py-4">
                  <span v-bind:style="{ 'font-size': font_size_who_icon }"
                    >🏭</span
                  >
                </div>
                <div
                  class="who_name text-center d-block"
                  v-bind:style="{ 'font-size': font_size_who_name }"
                >
                  {{ $t("lp_for_who_companies") }}
                </div>
              </div>
              <span
                class="who_text"
                v-bind:style="{ 'font-size': font_size_who_text }"
              >
                <span class="who_description" v-html="$t('lp_for_who_companies_text_1')"></span>
                <br />
                <br />
                <span class="who_description" v-html="$t('lp_for_who_companies_text_2')"></span>
                <br />
                <br />
                <span class="who_description" v-html="$t('lp_for_who_companies_text_3')"></span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- RSPP -->
    <div data-aos="fade-left" class="who_owners">
      <v-row>
        <v-col cols="12" offset-sm="4" sm="8">
          <v-card color="#ffffffb3">
            <v-card-text class="pa-6">
              <div class="card_heading">
                <div class="text-center py-4">
                  <span v-bind:style="{ 'font-size': font_size_who_icon }"
                    >👨🏽‍💼</span
                  >
                </div>
                <div
                  class="who_name text-center d-block"
                  v-bind:style="{ 'font-size': font_size_who_name }"
                >
                  {{ $t("lp_for_who_safety_manager") }}
                </div>
              </div>
              <span
                class="who_text"
                v-bind:style="{ 'font-size': font_size_who_text }"
              >
                <span class="who_description" v-html="$t('lp_for_who_safety_manager_text_1')"></span>
                <br />
                <br />
                <span class="who_description" v-html="$t('lp_for_who_safety_manager_text_2')"></span>
                <br />
                <br />
                <span class="who_description" v-html="$t('lp_for_who_safety_manager_text_3')"></span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Consultant -->
    <div data-aos="fade-right" class="who_owners">
      <v-row>
        <v-col cols="12" sm="8">
          <v-card color="#ffffffb3">
            <v-card-text class="pa-6">
              <div class="card_heading">
                <div class="text-center py-4">
                  <span v-bind:style="{ 'font-size': font_size_who_icon }"
                    >👩‍💻</span
                  >
                </div>
                <div
                  class="who_name text-center d-block"
                  v-bind:style="{ 'font-size': font_size_who_name }"
                >
                  {{ $t("lp_for_who_consultants") }}
                </div>
              </div>
              <span
                class="who_text"
                v-bind:style="{ 'font-size': font_size_who_text }"
              >
                <span class="who_description" v-html="$t('lp_for_who_consultants_text_1')"></span>
                <br />
                <br />
                <span class="who_description" v-html="$t('lp_for_who_consultants_text_2')"></span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- 
    E ovviamente per tutte quelle realtà che integrano al proprio interno la
    completa gestione della sicurezza. -->
  </div>
</template>

<script>
import "aos/dist/aos.css";

export default {
  props: {},
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {},
  created() {},
  computed: {
    font_size_title() {
      if (this.$vuetify.breakpoint.xs) {
        return "1.6rem";
      } else if (this.$vuetify.breakpoint.sm) {
        return "1.8rem";
      } else if (this.$vuetify.breakpoint.md) {
        return "2rem";
      } else if (this.$vuetify.breakpoint.lg) {
        return "2.5rem";
      } else if (this.$vuetify.breakpoint.xl) {
        return "3rem";
      }
      return "1rem"
    },
    font_size_who_icon() {
      if (this.$vuetify.breakpoint.xs) {
        return "6rem";
      } else if (this.$vuetify.breakpoint.sm) {
        return "6rem";
      } else if (this.$vuetify.breakpoint.md) {
        return "5rem";
      } else if (this.$vuetify.breakpoint.lg) {
        return "5rem";
      } else if (this.$vuetify.breakpoint.xl) {
        return "8rem";
      }
      return "1rem"
    },
    font_size_who_name() {
      if (this.$vuetify.breakpoint.xs) {
        return "1.3rem";
      } else if (this.$vuetify.breakpoint.sm) {
        return "1.8rem";
      } else if (this.$vuetify.breakpoint.md) {
        return "1.6rem";
      } else if (this.$vuetify.breakpoint.lg) {
        return "1.8rem";
      } else if (this.$vuetify.breakpoint.xl) {
        return "2rem";
      }
      return "1rem"
    },
    font_size_who_text() {
      if (this.$vuetify.breakpoint.xs) {
        return "1rem";
      } else if (this.$vuetify.breakpoint.sm) {
        return "1rem";
      } else if (this.$vuetify.breakpoint.md) {
        return "1rem";
      } else if (this.$vuetify.breakpoint.lg) {
        return "1rem";
      } else if (this.$vuetify.breakpoint.xl) {
        return "1.1rem";
      }
      return "1rem"
    },
  },
  watch: {},
};
</script>

<style scoped>
/* .who_title {
  font-size: 1.4rem;
} */

.who_name {
  font-weight: bold;
  padding-top: 1rem;
}

.card_heading {
  padding: 2rem;
}
.who_text {
  /* font-size:3rem; */
  /* text-shadow: 0px 0px 1px rgb(0, 0, 0); */
  text-justify: inter-word;
}

.who_description {
  text-align: justify;
}

.small_screen_big_icon {
  font-size: 3rem;
  text-align: center;
  padding-bottom: 2rem;
}
</style>